import "./login.css";
import visibility_off from "../../assets/visibility_off.svg";
import visibility from "../../assets/visibility.svg";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import axios from "../api/axios";
import Sidebars from "../sidebars/sidebars";
import { useEncryptedData } from "../../utils/encryptedData";
import { toast } from "react-toastify";

const Login = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

  axios.defaults.withCredentials = true;

  const ls = useEncryptedData();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const v_off = visibility_off;
  const v_on = visibility;

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [shown, setShown] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    emailRef.current.value = email;
  }, [email]);

  useEffect(() => {
    passwordRef.current.value = password;
  }, [password]);

  const handleValidation = async (e) => {
    e.preventDefault();

    const enteredEmail = emailRef.current.value;
    const enteredPassword = passwordRef.current.value;

    const toastId = toast.loading("ingresando...", {});

    await axios
      .request({
        method: "POST",
        url: "login/",
        headers: {
          "content-type": "application/json",
        },
        data: JSON.stringify({
          username: enteredEmail,
          password: enteredPassword,
        }),
      })
      .then((response) => {
        ls.set("token", { user: response.data }, { ttl: 3600 });

        toast.dismiss(toastId);

        setEmail("");
        setPassword("");

        navigate(from, { replace: true });
      })
      .catch((error) => {
        toast.dismiss(toastId);

        if (error.response?.status >= 400 && error.response?.status < 500) {
          toast.error("Error: " + error.response.data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (error.response?.status >= 500) {
          toast.error("Error: " + error.response.data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("El servidor no responde", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  const switchShown = () => setShown(!shown);

  const loginSubmit = (e) => {
    e.preventDefault();
    handleValidation(e);
  };

  return (
    <>
      <Sidebars></Sidebars>

      <div className="background master">
        <div className="color-overlay d-flex justify-content-center align-items-center color-font">
          <h2 className="titleLogin">Bienvenido a Proveedores LBO</h2>
          <Form className="rounded" onSubmit={loginSubmit} autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>Email o Nombre de usuario:</Form.Label>
              <Form.Control
                ref={emailRef}
                type="text"
                placeholder="Escribir email o nombre de usuario"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña:</Form.Label>
              <div className="input-group">
                <Form.Control
                  ref={passwordRef}
                  type={shown ? "text" : "password"}
                  placeholder="Contraseña"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" className="button" onClick={switchShown}>
                  {shown ? (
                    <img src={v_off} alt="ver_off" width="20" height="20"></img>
                  ) : (
                    <img src={v_on} alt="ver on" width="20" height="20"></img>
                  )}
                </button>
              </div>
            </Form.Group>
            <button className="button-18" type="submit">
              Login
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
