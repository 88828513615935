import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import axios from '../api/axios';

function SelectState({ isNotNullOrNaN, stateInv, idInv, toast, name }) {
  let currentState = parseInt(stateInv);

  let listState = [
    'Rechazado',
    'Pendiente',
    'Autorizado',
    'Contabilizado',
    'Por Contabilizar',
    'Pagado',
  ];

  let colors = ['danger', 'warning', 'success', 'secondary', 'info', 'success'];

  const [invoicesState, setinvoicesState] = useState(currentState);
  const [later, setLater] = useState(currentState + 1);
  const [refused, setRefused] = useState(currentState === 1 ? true : false);
  const [contabilizar, setContabilizar] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [dataStateShow, setDataStateShow] = useState(null);

  useEffect(() => {
    if (invoicesState === 0) {
      setContabilizar(false);
      setLater(null);
      setRefused(false);
    }
    if (invoicesState === 2) {
      setRefused(true);
      setContabilizar(true);
    }
    if (invoicesState === 3 || invoicesState === 4) {
      if (isNotNullOrNaN) {
        setLater(5);
        setContabilizar(false);
      } else {
        setLater(null);
        setContabilizar(false);
      }
    } else if (invoicesState === 5) {
      setLater(null);
    }
  }, [invoicesState]);

  const changeState = async (e, indexState, id) => {
    if (!showConfirmationModal) {
      setShowConfirmationModal(true);
      setDataStateShow(indexState);
    } else {
      let currentState = invoicesState;
      let currentStateLater = later;

      e.preventDefault();

      let options = {};

      let state = indexState <= 0 ? 0 : indexState;
      switch (state) {
        case 0:
          options = {
            method: 'PATCH',
            url: `facturas/state/${id}`,
            data: {
              state: '0',
            },
          };
          break;
        case 1:
          options = {
            method: 'PATCH',
            url: `facturas/state/${id}`,
            data: {
              state: '1',
            },
          };
          break;
        case 2:
          options = {
            method: 'PATCH',
            url: `facturas/state/${id}`,
            data: {
              state: '2',
            },
          };
          break;
        case 3:
          options = {
            method: 'PATCH',
            url: `facturas/state/${id}`,
            data: {
              state: '3',
            },
          };
          break;
        case 4:
          options = {
            method: 'PATCH',
            url: `facturas/state/${id}`,
            data: {
              state: '4',
            },
          };
          break;
        case 5:
          options = {
            method: 'PATCH',
            url: `facturas/state/${id}`,
            data: {
              state: '5',
            },
          };
          break;
        default:
          return;
      }

      const toastId = toast.loading('Cambiando estado de factura...');
      await axios
        .request(options)
        .then(function (response) {
          setinvoicesState(parseInt(response.data.state));

          if (response.data.state === '0') {
            setContabilizar(false);
            setLater(null);
            setRefused(false);
          }
          if (response.data.state === '2') {
            setContabilizar(true);
            setRefused(true);
          }

          if (response.data.state === '3' || response.data.state === '4') {
            setLater(5);
            setContabilizar(false);
          } else if (response.data.state === '5') {
            setLater(null);
          } else {
            setLater(parseInt(response.data.state) + 1);
          }
          toast.dismiss(toastId);
          toast.success(
            'Se modifico la factura: ' +
              idInv +
              ' al estado ' +
              listState[response.data.state] +
              ' con éxito',
          );
        })
        .catch(function (error) {
          toast.dismiss(toastId);
          console.error(error);
          setinvoicesState(currentState);
          setLater(currentStateLater);
          options = {};
          toast.error(error.response.data.error);
        });

      setShowConfirmationModal(false);
      setDataStateShow(null);
    }
  };

  const cancelStateChange = () => {
    setShowConfirmationModal(false);
    setDataStateShow(null);
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant={colors[invoicesState]}
          // eslint-disable-next-line react/style-prop-object
          style={{ 'font-size': '100%' }}
        >
          {listState[invoicesState]}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {later ? (
            <>
              <Dropdown.Item
                onClick={(e) => {
                  changeState(e, later, idInv);
                }}
              >
                {listState[later] === undefined ? '' : listState[later]}
              </Dropdown.Item>
              <Dropdown.Divider></Dropdown.Divider>
            </>
          ) : (
            <></>
          )}
          {contabilizar ? (
            <>
              <Dropdown.Item
                onClick={(e) => {
                  changeState(e, 4, idInv);
                }}
              >
                {listState[4]}
              </Dropdown.Item>
              <Dropdown.Divider></Dropdown.Divider>
            </>
          ) : (
            <></>
          )}
          {refused ? (
            <Dropdown.Item
              className="text-danger"
              onClick={(e) => {
                changeState(e, 0, idInv);
              }}
            >
              <b>{listState[0]}</b>
            </Dropdown.Item>
          ) : (
            <></>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={showConfirmationModal} onHide={cancelStateChange}>
        <Modal.Header closeButton>
          <Modal.Title>Estado de factura</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Estás a punto de cambiar a: {listState[dataStateShow]}. La factura de{' '}
          <b>{name}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={cancelStateChange}>
            Volver
          </Button>
          <Button
            variant={colors[dataStateShow]}
            onClick={(e) => {
              changeState(e, dataStateShow, idInv);
            }}
          >
            {dataStateShow === 0 ? 'Rechazar' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SelectState;
