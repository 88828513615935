import React, { useState } from "react";
import { Link } from "react-router-dom";
import edit from "../../../assets/edit.svg";
import reintegro_info from "../../../assets/info.svg";
import { useEncryptedData } from "../../../utils/encryptedData";
import axios from "../../api/axios";
import SelectState from "../../other/buttonForm";
import ModalInput from "../../other/modal";
import ModalCalendar from "../../other/modalCalendar";
import ModalObservation from "../../other/modalObservation";
import "./TableInvoices.css";

axios.defaults.withCredentials = true;

export const DataTable = ({ invoice, toast }) => {
  const ls = useEncryptedData();

  let cbu;
  let cbu2;

  if (invoice.cbu_fact === "no disponible" || invoice.cbu_fact === null) {
    cbu = invoice.cbu_fact;
    cbu2 = "";
  } else {
    cbu = invoice.cbu_fact.slice(0, 11);
    cbu2 = invoice.cbu_fact.slice(11);
  }

  const strContacts =
    invoice.contact !== null && invoice.contact !== ""
      ? invoice.contact.slice(0, -11)
      : invoice.contact;

  const strUploaderUser =
    invoice.uploader_user !== null ? invoice.uploader_user : "";

  const userData = ls.get("token", { secret: process.env.REACT_APP_SECRET });
  const isContact = invoice.contact === userData.user.email;
  const isContabilidad = userData.user.rol.includes("Conciliaciones");
  const isFinanza = userData.user.rol.includes("Finanzas");

  let colors = ["danger", "warning", "success", "secondary", "info", "success"];

  const formatterDolar = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const [retencion, setRetencion] = useState(invoice.retenciones);
  const [fact_pdf, setfact_pdf] = useState(null);
  const [viewPdf, setViewPdf] = useState(invoice.comp_retenciones);

  const [observation, setObservation] = useState(
    invoice.observation === null ? "" : invoice.observation
  );

  const [neto, setNeto] = useState(
    formatterDolar.format(invoice.amount - invoice.retenciones).replace("€", "")
  );

  let monto = formatterDolar.format(invoice.amount).replace("€", "");

  let date = new Date(invoice.date);
  const [payDate, setPayDate] = useState(new Date(invoice.pay_date));

  const changeRetecion = async (e) => {
    e.preventDefault();

    let options = {};

    if (fact_pdf !== null) {
      options = {
        method: "PATCH",
        headers: { "content-type": "multipart/form-data" },
        url: `facturas/retenciones/${invoice.id}`,
        data: {
          retenciones: retencion,
          comp_retenciones: fact_pdf,
        },
      };
    } else {
      options = {
        method: "PATCH",
        headers: { "content-type": "multipart/form-data" },
        url: `facturas/retenciones/${invoice.id}`,
        data: {
          retenciones: retencion,
        },
      };
    }

    await axios
      .request(options)
      .then(function (response) {
        toast.success(
          "se modifico reteción de la Factura " + invoice.id + " con exito"
        );

        setNeto(
          formatterDolar.format(invoice.amount - retencion).replace("€", "")
        );
        setRetencion(response.data.retenciones);
        setfact_pdf(fact_pdf);
        setViewPdf(
          response.data.comp_retencion === null
            ? invoice.comp_retenciones
            : response.data.comp_retencion
        );
      })
      .catch(function (error) {
        toast.warning("No se modifico la retecion de la factura " + invoice.id);
        setRetencion(invoice.retenciones);
        setfact_pdf(fact_pdf);
        setViewPdf(invoice.comp_retencion);
      });
  };

  const getPdf = async (e) => {
    e.preventDefault();

    if (invoice.fact_pdf) {
      window.open(process.env.REACT_APP_PROVEEDORES_BACK + invoice.fact_pdf);
    } else {
      toast.error("No se pudo vizualizar PDF");
    }
  };

  return (
    <tr
      className={
        invoice.is_representative
          ? "table-" + colors[6]
          : "table-" + colors[invoice.state]
      }
    >
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {invoice.reintegro ? (
          <img
            className="imgR"
            src={reintegro_info}
            alt="es reintegro"
            width="15"
            height="15"
          ></img>
        ) : (
          <></>
        )}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        <b>{invoice.empresa ? invoice.empresa : ""}</b>
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear()}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {(isContact || isContabilidad) && invoice.state !== "5" ? (
          <ModalCalendar
            payDate={payDate}
            setPayDate={setPayDate}
            idInv={invoice.id}
            toast={toast}
          ></ModalCalendar>
        ) : (
          payDate.getDate() +
          "/" +
          (payDate.getMonth() + 1) +
          "/" +
          payDate.getFullYear()
        )}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {invoice.user}
      </td>
      <td
        className={
          invoice.cbu_fact === "no disponible" || invoice.cbu_fact === null
            ? invoice.is_representative
              ? "text-danger table-" + colors[6]
              : "text-danger table-" + colors[invoice.state]
            : invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {invoice.cbu_fact === "no disponible" ? cbu : cbu}
        <br />
        {cbu2}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {invoice.type}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {invoice.concept}
      </td>
      <td
        className={
          invoice.is_representative
            ? "montostaff table-" + colors[6]
            : "montostaff table-" + colors[invoice.state]
        }
      >
        {invoice.currency + " " + monto}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {isContabilidad || isFinanza ? (
          <ModalInput
            retenciones={retencion}
            changeRetecion={changeRetecion}
            setRetencion={setRetencion}
            setfact_pdf={setfact_pdf}
            comp_retenciones={viewPdf}
            toast={toast}
          ></ModalInput>
        ) : (
          <>
            {retencion !== null && !isNaN(retencion) ? (
              formatterDolar.format(retencion).replace("€", "")
            ) : (
              <p>
                <b>sin análisis</b>
              </p>
            )}
          </>
        )}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {invoice.currency + " " + neto}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        {strContacts + "/"} <br></br> {strUploaderUser}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        <button className="button-4" onClick={getPdf}>
          PDF
        </button>

        <br></br>
        {isContabilidad ? (
          <Link to={`/factura/${invoice.id}`} state={{ invoices: invoice }}>
            <button
              type="button"
              className="btn btn-sm btn-light"
              style={{
                display: "flex",
                margin: "auto",
                "margin-top": "10%",
              }}
            >
              <img src={edit} alt="edit" height={10} width={10}></img>
            </button>
          </Link>
        ) : (
          <></>
        )}
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        <ModalObservation
          observation={observation}
          setObservation={setObservation}
          idInv={invoice.id}
          toast={toast}
        ></ModalObservation>
      </td>
      <td
        className={
          invoice.is_representative
            ? "table-" + colors[6]
            : "table-" + colors[invoice.state]
        }
      >
        <SelectState
          className="dropdown"
          isNotNullOrNaN={
            invoice.retenciones !== null && !isNaN(invoice.retenciones)
          }
          toast={toast}
          stateInv={invoice.state}
          idInv={invoice.id}
          key={invoice.id}
          name={invoice.user}
        ></SelectState>
      </td>
    </tr>
  );
};
