import "./App.css";
import UploadInvoces from "./components/invoices/uploadInvoces";
import Login from "./components/login/login";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import { Home } from "./components/home/Home";
import TableInvoices from "./components/invoices/Table/TableInvoices";
import MyInvoices from "./components/invoices/Table/MyInvoices";
import Suppliers from "./components/invoices/uploadStaffInvoices";
import Sidebars from "./components/sidebars/sidebars";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import TableProveedores from "./components/Proveedores/TableProvider";
import EditProvider from "./components/Proveedores/editProveedores";
import AltaProvider from "./components/Proveedores/altaProveedores";
import EditInvoices from "./components/invoices/Table/EditInvoices";
import TableRepresentantes from "./components/representantes/table-representantes";
import RepresentantesDetail from "./components/representantes/detail-representantes";

function App() {
  const navigate = useNavigate();

  const goToBack = () => navigate(-1);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route
            path="unauthorized"
            element={
              <div>
                <Sidebars></Sidebars>
                <h2 className="Titulo">No autorizado</h2>
                <Button variant="primary" onClick={goToBack}>
                  Volver
                </Button>
              </div>
            }
          />
          {/* catch all */}
          <Route
            path="*"
            element={
              <div>
                <Sidebars></Sidebars>
                <h2 className="Titulo">
                  Error 404. no existe la ruta a la que intenta acceder
                </h2>
                <></>
                <Button variant="primary" onClick={goToBack}>
                  Volver
                </Button>
              </div>
            }
          />

          {/* we want to protect these routes */}
          <Route
            element={
              <RequireAuth
                rolesAuths={[
                  "Proveedor",
                  "Staff LBO",
                  "Finanzas",
                  "Conciliaciones",
                ]}
              />
            }
          >
            <Route path="/" element={<Home />} />
          </Route>

          <Route
            element={
              <RequireAuth rolesAuths={["Proveedor"]} />
            }
          >
            <Route
              path="/subirfacturas"
              element={<UploadInvoces></UploadInvoces>}
            ></Route>
          </Route>

          <Route
            element={
              <RequireAuth
                rolesAuths={["Proveedor", "Staff LBO"]}
              />
            }
          >
            <Route path="/misfacturas/:cuil" element={<MyInvoices />}></Route>
          </Route>

          <Route
            element={
              <RequireAuth
                rolesAuths={["Staff LBO", "Finanzas", "Conciliaciones"]}
              />
            }
          >
            <Route
              path="/Staff/subirfacturas"
              element={<Suppliers></Suppliers>}
            ></Route>
            <Route
              path="/proveedores"
              element={<TableProveedores></TableProveedores>}
            ></Route>
            <Route
              path="/proveedores/alta"
              element={<AltaProvider></AltaProvider>}
            ></Route>
          </Route>

          <Route
            element={
              <RequireAuth rolesAuths={["Finanzas", "Conciliaciones"]} />
            }
          >
            <Route
              path="/representantes"
              element={<TableRepresentantes></TableRepresentantes>}
            />

            <Route
            path="/representantes/detalle"
            element={<RepresentantesDetail></RepresentantesDetail>}
            />

            <Route
              path="/Staff/facturas"
              element={<TableInvoices></TableInvoices>}
            />
            <Route
              path="/proveedores/edit/:cuil"
              element={<EditProvider></EditProvider>}
            ></Route>
          </Route>
          <Route rolesAuths={["Conciliaciones"]}>
            <Route
              path="/factura/:id"
              element={<EditInvoices></EditInvoices>}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
