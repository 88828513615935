import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
const FilterCalendar = ({ label, filterDate, setFilterDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slotProps={{ textField: { size: "small" } }}
        margin="normal"
        label={label}
        format="DD-MM-YYYY"
        value={filterDate}
        onChange={(newDate) => {
          setFilterDate(new Date(newDate));
        }}
      />
    </LocalizationProvider>
  );
};

export default FilterCalendar;
