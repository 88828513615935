import React, { useEffect, useState, useMemo } from 'react'; // Importar React
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import add from '../../assets/add proveedor.svg';
import { listColumsRepresentantes } from '../../utils/Columns-representantes';
import { useEncryptedData } from '../../utils/encryptedData';
import axios from '../api/axios';
import Sidebars from '../sidebars/sidebars';

const TableRepresentantes = () => {
  const ls = useEncryptedData();
  const userData = ls.get("token", {
    secret: process.env.REACT_APP_SECRET,
  });
  let isFinanza = false;
  let isContabilidad = false;

  if (userData !== null && userData !== undefined) {
    isFinanza = userData.user.rol.includes("Finanzas");
    isContabilidad = userData.user.rol.includes("Conciliaciones");
  }

  const [pending, setPending] = useState(true);
  const [listRepre, setListRepre] = useState([]); // Cambio correcto a 'setListRepre'
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const getListRepre = async () => {
    const optionsRepre = {
      method: "GET",
      url: "representantes/",
    };

    await axios
      .request(optionsRepre)
      .then((response) => {
        setPending(false);
        setListRepre(response?.data?.data ?? []); // Cambio correcto a 'setListRepre'
      })
      .catch((error) => {
        toast.error("Error al cargar representantes");
      });
  };

  const columns = listColumsRepresentantes(isFinanza, isContabilidad);

  const filteredItems = listRepre.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilter = (e) => {  // Cambio de nombre a 'handleFilter'
    setFilterText(e.target.value);
  };

  const handleClear = () => {  // Cambio de nombre a 'handleClear'
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = useMemo(() => { // useMemo en lugar de React.useMemo
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="Buscar..."
          value={filterText}
          onChange={handleFilter} // Uso correcto de 'handleFilter'
          style={{
            padding: '5px',
            marginRight: '10px',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
        <button
          onClick={handleClear} // Uso correcto de 'handleClear'
          style={{
            padding: '5px 10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#007bff',
            color: 'white',
            cursor: 'pointer',
          }}
        >
          Limpiar
        </button>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getListRepre();
  }, []);

  return (
    <>
      <Sidebars></Sidebars>
      <div
        style={{
          width: '80%',
          margin: 'auto',
        }}
      >
        <br></br>
     
        <div className="card text-sm-light mb-3 div-header-info">
          <div className="card-body">
            <DataTable
              title="Tabla Representantes"
              columns={columns}
              data={filteredItems}
              highlightOnHove
              direction="auto"
              pagination
              responsive
              progressPending={pending}
              highlightOnHover
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            ></DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableRepresentantes;
