import { Link } from 'react-router-dom';
import edit from '../assets/edit.svg';

export const listColumsRepresentantes = (
  isFinanza = false,
  isContabilidad = false,
) => {
  if (isFinanza || isContabilidad) {
    return [
      {
        name: 'Nombre',
        selector: (row) => row.name,
        sortable: true,
        reorder: true,
      },

      {
        name: 'Cuil',
        selector: (row) => row.cuil,
      },

      {
        name: 'email',
        selector: (row) => row.email,
        sortable: true,
        reorder: true,
      },
      {
        name: 'ver cuenta bancaria',
        selector: (row) => {
          return (
            <Link
              to={`/representantes/detalle`}
              state={{
                name: row.name,
                email: row.email,
                cuil: row.cuil,
                id: row.id,
              }}
            >
              <button type="button" class="btn btn-light">
                <img src={edit} alt="edit" height={20} width={20}></img>
              </button>
            </Link>
          );
        },
      }
    ];
  } else {
    return [
      {
        name: 'Nombre',
        selector: (row) => row.name,
        sortable: true,
        reorder: true,
      },

      {
        name: 'Cuil',
        selector: (row) => row.cuil,
      },

      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        reorder: true,
      }
    ];
  }
};
