import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import back from '../../../assets/back.png';
import { useEncryptedData } from '../../../utils/encryptedData';
import { contacts } from '../../../utils/listContacs';
import CurrencyInput from '../../../utils/money';
import axios from '../../api/axios';
import Sidebars from '../../sidebars/sidebars';

const EditInvoices = () => {
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  axios.defaults.withCredentials = true;

  const formatterDolar = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  const { invoices } = useLocation().state;

  const ls = useEncryptedData();
  const userData = ls.get('token', { secret: process.env.REACT_APP_SECRET });

  const [isChecked, setIsChecked] = useState(
    invoices.reintegro ? invoices.reintegro : false,
  );

  const empresas = [
    { label: 'LBO S.A', value: 'LBO S.A' },
    { label: 'Agrofidi', value: 'Agrofidi' },
  ];

  const coins = ['$', 'USD'];

  const handleOnChange = (e) => {
    if (e.target.checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const options = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'Otro', value: 'Otro' },
  ];

  // data invoices
  const [cbu, setCbu] = useState(invoices.cbu_fact || '');
  const [concept, setconcept] = useState(invoices.concept || '');
  const [amount, setamount] = useState(invoices.amount || 0.0);
  const [inputamount, setinputamount] = useState(
    formatterDolar.format(invoices.amount).replace('€', ''),
  );
  const [contact, setContact] = useState(invoices.contact || '');
  const [type, settype] = useState(invoices.type || '');
  const [observation, setobservation] = useState(
    invoices.observation ? invoices.observation : '',
  );
  const [empresa, setEmpresa] = useState(invoices.empresa || '');
  const [button, setButton] = useState(true);
  const [currency, setCurrency] = useState(invoices.currency || '$');
  const initialCurrency = invoices.currency || '$';

  function handleSelect(e) {
    setCurrency(e.target.value);
  }

  const postInvoices = async (e) => {
    e.preventDefault();

    const toastId = toast.loading('Editando Factura...');

    setButton(true);

    let value = inputamount.replace(/\./g, '').replace(',', '.');

    let amount_float = parseFloat(value);

    const option = {
      method: 'PUT',
      url: 'facturas/edit/' + invoices.id,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: {
        type: type,
        concept: concept,
        amount: amount_float,
        cbu_fact: cbu,
        contact: contact,
        cuil: invoices.cuil,
        reintegro: isChecked,
        empresa: empresa,
        observation: observation,
        currency: currency,
        retenciones:
          type === options[1].value || type === options[2].value
            ? 0
            : invoices.retenciones,
      },
    };

    await axios
      .request(option)
      .then(function (response) {
        if (response.status === 201) {
          toast.dismiss(toastId);
          toast.success('La Factura se editó exitosamente.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch(function (error) {
        console.error(error);

        if (error?.response?.status > 400 && error?.response?.status < 500) {
          toast.dismiss(toastId);

          if (Array.isArray(error.response.data.error)) {
            error.response.data.error.forEach((err) => {
              toast.error(err, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            });
          } else {
            toast.error('Error: ' + error.response.data.error, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        } else {
          toast.dismiss(toastId);
          toast.error('Error: ' + error.response.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          setButton(false);
        }
      });
  };

  const viewButton = () => {
    if (inputamount && concept && empresa && contact) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const money = (e) => {
    if (e.target.value === '') {
      setinputamount('');
      return;
    } else if (e.target.value === ',00' || e.target.value === '0,00') {
      setinputamount('');
      return;
    } else if (!e.target.value.includes(',')) {
      let amount = e.target.value;
      setinputamount(amount + ',00');
      return;
    } else if (e.target.value.includes(',')) {
      let amount = e.target.value.split(',');
      if (amount[1] === '' || amount[1] === '0') {
        setinputamount(amount[0] + ',00');
        return;
      }
      let amount2 = amount[0] + ',' + amount[1];
      setinputamount(amount2);
      return;
    } else {
      setinputamount(e.target.value);
    }
  };

  const handleChangeCBU = (event) => {
    if (/^[0-9]{0,22}$/.test(event.target.value)) {
      setCbu(event.target.value);
    }
  };

  useEffect(() => {
    if (isChecked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, []);

  useEffect(() => {
    viewButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, concept, empresa]);

  return (
    <div>
      <Sidebars></Sidebars>
      <div className="divform column">
        <h3
          className="titleform"
          style={{
            'margin-top': '1%',
          }}
        >
          <Link to={'/Staff/facturas'}>
            <button type="button" class="btn btn-light">
              <img src={back} alt="volver" height={30} width={30}></img>
            </button>
          </Link>
        </h3>

        <br></br>
        <h2 className="titleform">Editar Facturas</h2>

        <br></br>

        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Proveedor:</label>
          <div className="col-sm-5">
            <p className="form-control-plaintext">{invoices.user || ''}</p>
          </div>
          <label className="col-sm-2 col-form-label">Cuil:</label>
          <div className="col-sm-5">
            <p className={'form-control-plaintext'}>{invoices.cuil || ''}</p>
          </div>
        </div>
        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Empresa:
          </label>
          <div className="col-sm-10">
            <Select
              defaultValue={{
                label: invoices.empresa,
                value: invoices.empresa,
              }}
              options={empresas}
              onChange={(e) => {
                setEmpresa(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#0d9963',
                  primary: '#0d9963b5',
                },
              })}
              className="form-control-plaintext"
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    size="small"
                    checked={isChecked}
                    onChange={handleOnChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Es reintegro"
                size="small"
              />
            </FormGroup>
          </div>
        </div>

        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Tipo:
          </label>
          <div className="col-sm-10">
            <Select
              defaultValue={{
                label: invoices.type,
                value: invoices.type,
              }}
              options={options}
              onChange={(e) => {
                settype(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#0d9963',
                  primary: '#0d9963b5',
                },
              })}
              className="form-control-plaintext"
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">CBU:</label>
          <div className="col-sm-10">
            <input
              size={22}
              maxlength={22}
              type="text"
              className={'form-control'}
              value={cbu}
              onChange={handleChangeCBU}
              required
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Monto:</label>
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <CurrencyInput
              placeholder=""
              type="text"
              className="form-control"
              value={inputamount}
              onChange={(e) => {
                money(e);
              }}
              required
            />
            <Form.Select
              style={{
                width: '20%',
                'margin-left': '1rem',
              }}
              onChange={handleSelect}
            >
              <option defaultValue={initialCurrency}>{initialCurrency}</option>
              <option value={initialCurrency === '$' ? coins[1] : coins[0]}>
                {initialCurrency === '$' ? coins[1] : coins[0]}
              </option>
            </Form.Select>
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Concepto:</label>
          <div className="col-sm-10">
            <input
              value={concept}
              type="text"
              className="form-control"
              maxLength={100}
              onChange={(e) => setconcept(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Observación:</label>
          <div className="col-sm-10">
            <input
              value={observation}
              maxLength={100}
              type="text"
              className="form-control"
              placeholder="(opcional)"
              onChange={(e) => setobservation(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="mb-3 rowform selectMobile">
          <label className="col-sm-2 col-form-label">Contacto LBO:</label>
          <div className="col-sm-10">
            <Select
              defaultValue={{
                label: invoices.contact,
                value: invoices.contact,
              }}
              options={contacts.map((sup) => ({
                value: sup,
                label: sup,
              }))}
              onChange={(e) => {
                setContact(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#0d9963',
                  primary: '#0d9963b5',
                },
              })}
              className="form-control-plaintext"
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <button
            disabled={button}
            className={button ? 'button-9 act' : 'button-9'}
            onClick={postInvoices}
          >
            Editar Factura
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditInvoices;
