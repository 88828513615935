import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import axios from "../api/axios";
import close from "../../assets/close.svg";

const ModalFormCuentaBancaria = ({
  title,
  userId,
  cuil,
  getBanks = () => {},
  bankAccount = undefined,
}) => {
  const accountsTypes = [
    { label: "CC", value: "CC" },
    { label: "CA", value: "CA" },
  ];

  const currencies = [
    { label: "USD", value: "USD" },
    { label: "$", value: "$" },
  ]

  const [accountType, setAccountType] = useState(
    bankAccount?.accountType ?? "CC"
  );

  const [currency, setCurrency] = useState(
    bankAccount?.currentAccount ?? "$"
  )

  const [cuitCuil, setCuitCuil] = useState(cuil ?? "");

  const [cbu, setCbu] = useState(bankAccount?.cbu ?? "");
  const [alias, setAlias] = useState(bankAccount?.alias ?? "");
  const [bank, setBank] = useState(bankAccount?.bank ?? "");

  const [show, setShow] = useState(false);

  const handleChangeCBU = (event) => {
    if (/^[0-9]{0,22}$/.test(event.target.value)) {
      setCbu(event.target.value);
    }
  };

  const createOrEditAccount = async (e) => {
    e.preventDefault();

    if (bankAccount) {
      const data = {
        accountType: accountType,
        cbu: cbu,
        alias: alias,
        cuitCuil: cuitCuil,
        currency: currency,
        bankName: ''
      };

      try {
        const response = await axios.put(
          "representantes/" + userId + "/bank-account/" + bankAccount.id,
          data
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      const data = {
        accountType: accountType,
        cbu: cbu,
        alias: alias,
        cuitCuil: cuitCuil,
        currency: currency,
        bankName: ''
      };

      try {
        const response = await axios.post(
          "representantes/" + userId + "/bank-account/",
          data
        );
      } catch (error) {
        console.error(error);
      }
    }

    getBanks();
    setShow(false);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {title}
      </Button>

      <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="mb-3 rowform selectMobile">
                <label for="staticEmail" className="col-sm-2 col-form-label">
                  Tipo de cuenta:
                </label>
                <div className="col-sm-10">
                  <Select
                    options={accountsTypes}
                    defaultValue={accountsTypes.find(
                      (option) => option.value === accountType
                    )}
                    onChange={(e) => {
                      setAccountType(e.value);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#0d9963",
                        primary: "#0d9963b5",
                      },
                    })}
                    className="form-control-plaintext"
                  />
                </div>
              </div>
              <div className="mb-3 rowform selectMobile">
                <label for="staticEmail" className="col-sm-2 col-form-label">
                  Tipo de moneda:
                </label>
                <div className="col-sm-10">
                  <Select
                    options={currencies}
                    defaultValue={currencies.find(
                      (option) => option.value === currency
                    )}
                    onChange={(e) => {
                      setAccountType(e.value);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#0d9963",
                        primary: "#0d9963b5",
                      },
                    })}
                    className="form-control-plaintext"
                  />
                </div>
              </div>
              <div className="mb-3 rowform">
                <label className="col-sm-2 col-form-label">CBU:</label>
                <div className="col-sm-10">
                  <input
                    size="22"
                    maxlength="22"
                    type="text"
                    className="form-control"
                    value={cbu}
                    onChange={handleChangeCBU}
                  />
                </div>
              </div>
              <div className="mb-3 rowform">
                <label className="col-sm-2 col-form-label">Alias:</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={createOrEditAccount}>
            enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFormCuentaBancaria;
