import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import back from "../../assets/back.png";
import axios from "../api/axios";
import Sidebars from "../sidebars/sidebars";
import "./AltaProveedores.css";

const AltaProvider = () => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  axios.defaults.withCredentials = true;

  const [buttonDisable, setButttonDisable] = useState(true);
  const [cuil, setCuil] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cbu, setCbu] = useState("");
  const [cbuUSD, setCbuUSD] = useState("");
  const [usertype, setUsertype] = useState("");
  const [area, setArea] = useState("");

  const handleChangeCuit = (event) => {
    if (/^[0-9]{0,2}[0-9]{0,8}[0-9]{0,1}$/.test(event.target.value)) {
      setCuil(event.target.value);
    }
  };
  const handleChangeCBU = (event) => {
    if (/^[0-9]{0,22}$/.test(event.target.value)) {
      setCbu(event.target.value);
    }
  };

  const handleChangeCBUUSB = (event) => {
    if (/^[0-9]{0,22}$/.test(event.target.value)) {
      setCbuUSD(event.target.value);
    }
  };

  const options = [
    { label: "FYO", value: "FYO" },
    { label: "GRANOS", value: "Granos" },
    { label: "GENERAL", value: "General" },
    { label: "VALORES", value: "Valores" },
  ];

  const options2 = [
    { label: "HABITUAL", value: "Habitual" },
    { label: "EVENTUAL", value: "Eventual" },
  ];

  const viewButton = () => {
    if (
      cuil.trim() &&
      name.trim() &&
      email.trim() &&
      usertype.trim() &&
      area.trim()
    ) {
      setButttonDisable(false);
    } else {
      setButttonDisable(true);
    }
  };

  useEffect(() => {
    viewButton();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuil, name, email, usertype, area]);

  const PutProvider = async (e) => {
    e.preventDefault();

    setButttonDisable(true);

    const toastId = toast.loading("Cargando proveedor...", {
      autoClose: 10000,
    });

    if (cuil.length !== 11) {
      toast.dismiss(toastId);
      toast.error("Error: Cuit debe tener de 11 número", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        closeButton: true,
        progress: undefined,
        theme: "colored",
      });
      setButttonDisable(false);

      return;
    }

    if (!(cbu.trim() === "")) {
      if (cbu.length !== 22) {
        toast.dismiss(toastId);
        toast.error("Error: CBU debe tener de 22 número", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          closeButton: true,
          progress: undefined,
          theme: "colored",
        });
        setButttonDisable(false);

        return;
      }
    }

    const optionsProv = {
      method: "POST",
      url: "proveedores/create",
      data: {
        cbu: cbu.trim() !== "" ? cbu : null,
        cbu_usd: cbuUSD.trim() !== "" ? cbuUSD : null,
        is_representative: false,
        name: name,
        email: email,
        cuil: cuil,
        area: area,
        state: "1",
        usertype: usertype,
      },
    };

    await axios
      .request(optionsProv)
      .then((response) => {
        if (response.status === 201) {
          toast.dismiss(toastId);
          toast.success("Proveedor Creado", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setCuil("");
          setCbu("");
          setCbuUSD("");
          setEmail("");
          setName("");
          setButttonDisable(false);
        }
        toast.dismiss(toastId);
      })
      .catch((error) => {
        toast.dismiss(toastId);

        setButttonDisable(false);

        if (error.response.status === 404) {
          toast.error("Error: No existe el proveedor con cuil: ");
        } else if (error.response.status === 401) {
          toast.error("Error: Usuario no logueado o no autorizado");
        } else if (error.response.status === 400) {
          if (error.response.data["cuil"]) {
            toast.error(error.response.data.cuil[0]);
          }
        } else {
          toast.error("Error: No se creó el proveedor");
        }
      });
  };
  /*
  const isValidCBU = (cbu) => {
    let result = false;
    if (cbu) {
      const numeric = cbu.replace(/[^0-9]+/g, '');
      if (numeric.length === 22) {
        let block = numeric.slice(0, 8).split(''),
          validator = Number(block.pop()),
          sum = 0,
          multiplier = '7139713';
        for (const iIndex in block) {
          sum += +block[iIndex] * +multiplier[iIndex];
        }
        let difference = 10 - (sum % 10);
        if (
          (validator === 0 && difference % 10 === 0) ||
          (validator !== 0 && difference === validator)
        ) {
          block = numeric.slice(8).split('');
          // eslint-disable-next-line no-unused-expressions
          (validator = Number(block.pop())), (sum = 0);
          multiplier = '3971397139713';
          for (const iIndex in block) {
            sum += +block[iIndex] * +multiplier[iIndex];
          }
          difference = 10 - (sum % 10);
          result =
            (validator === 0 && difference % 10 === 0) ||
            (validator !== 0 && difference === validator);
        }
      }
    }
    return result;
  };
*/
  return (
    <div>
      <Sidebars></Sidebars>

      <div className="divform column">
        <div
          style={{
            display: "inline-flex",
            marginLeft: "-9%",
            marginTop: "2%",
            marginBottom: "2%",
          }}
        >
          <span className="titleform">
            <Link to={"/proveedores"}>
              <button type="button" class="btn btn-light">
                <img src={back} alt="volver" height={30} width={30}></img>
              </button>
            </Link>
          </span>
          <span
            style={{
              margin: "auto",
            }}
          >
            <h2 className="titleform">Alta Proveedores</h2>
          </span>
        </div>

        <br></br>

        <Alert
          key={"info"}
          variant={"info"}
          style={{
            fontSize: "110%",
          }}
        >
          Para pagarle al proveedor <b>es necesario vincularle un CBU</b>. Sin
          el CBU solo se pueden realizar reintegros.
        </Alert>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Cuil:</label>
          <div className="col-sm-10">
            <input
              size={11}
              maxlength={11}
              placeholder="Escribir cuil sin -"
              type="text"
              className="form-control"
              value={cuil}
              onChange={handleChangeCuit}
              required
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Razón Social:</label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <div class="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Email:</label>
          <div className="col-sm-10">
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">CBU:</label>
          <div className="col-sm-10">
            <input
              size={22}
              maxlength={22}
              type="text"
              className="form-control"
              placeholder=""
              value={cbu}
              onChange={handleChangeCBU}
              required
            />
          </div>
        </div>

        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">CBU USD:</label>
          <div className="col-sm-10">
            <input
              size={22}
              maxlength={22}
              type="text"
              className="form-control"
              placeholder="(opcional)"
              value={cbuUSD}
              onChange={handleChangeCBUUSB}
              required
            />
          </div>
        </div>

        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Área:
          </label>
          <div className="col-sm-10 selectMobile">
            <Select
              className="form-control-plaintext"
              options={options}
              onChange={(e) => {
                setArea(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
            />
          </div>
        </div>

        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Tipo:
          </label>
          <div className="col-sm-10">
            <Select
              className="form-control-plaintext"
              options={options2}
              onChange={(e) => {
                setUsertype(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <button
            disabled={buttonDisable}
            className={buttonDisable ? "button-9 act" : "button-9"}
            onClick={(e) => PutProvider(e)}
          >
            Cargar Proveedor
          </button>
        </div>
      </div>
    </div>
  );
};

export default AltaProvider;
