import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import axios from "../api/axios";

const ModalObservation = ({ observation, setObservation, idInv, toast }) => {
  const beforeObs = observation;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setObservation(beforeObs);
  };

  const handleShow = () => setShow(true);

  const changeObservation = async (e) => {
    e.preventDefault();

    const options = {
      method: "PATCH",
      url: `facturas/observaciones/${idInv}`,
      data: {
        observation: observation
      }
    };

    await axios
      .request(options)
      .then((response) => {
        setObservation(observation);

        toast.success("Observación modificada exitosamente.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        });
      })
      .cath((error) => {
        toast.success("El texto supera la cantidad de caracteres permitida.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        });
      });
  };
  return (
    <>
      <button className="button-48" onClick={handleShow}>
        <p class="text obser">
          {observation.length >= 12 ? (
            observation.slice(0, 10) + "..."
          ) : observation !== "" ? (
            observation
          ) : (
            <>{"            "}</>
          )}
        </p>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Observación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="mb-3">
                <textarea
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                  maxLength={100}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"></textarea>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={changeObservation}>
            Cambiar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalObservation;
