import axios from "../components/api/axios";
export const logout = async (e) => {
  e.preventDefault();

  const options = {
    method: "GET",
    url: "logout/"
  };

  await axios
    .request(options)
    .then(function (response) {})
    .catch(function (error) {
      console.error(error);
    });
};
