import axios from "../api/axios";
import { useState, useEffect } from "react";
import { FormInvoices } from "../other/formInvoices";
import Sidebars from "../sidebars/sidebars";
import { useEncryptedData } from "../../utils/encryptedData";

axios.defaults.withCredentials = true;
const UploadInvoces = () => {
  const ls = useEncryptedData();
  const userData = ls.get("token", { secret: process.env.REACT_APP_SECRET });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cuil, setCuil] = useState("");
  const [cbu, setCbu] = useState("");

  const getCBU = async (optionsCbu) => {
    await axios
      .request(optionsCbu)
      .then((response) => {
        setCbu(response.data.cbu ? response.data.cbu : "no disponible");
      })
      .catch((error) => {
        console.error(error);
        setCbu("no existe CBU");
      });
  };

  const provider = () => {
    setName(userData.user.name);
    setEmail(userData.user.email);
    setCuil(userData.user.cuil);
    getCBU({
      method: "GET",
      url: `cbu/${userData.user.cuil}`
    });
  };

  useEffect(() => {
    provider();
  }, []);

  return (
    <div>
      <Sidebars userData={userData}></Sidebars>
      <div>
        <FormInvoices
          user={name}
          email={email}
          cuil={cuil}
          cbu={cbu}
          isStaff={false}
          listProv={[]}></FormInvoices>
      </div>
    </div>
  );
};

export default UploadInvoces;
