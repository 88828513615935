import "./Home.css";
import Sidebars from "../sidebars/sidebars";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import list from "../../assets/list.svg";
import receipt from "../../assets/receipt.svg";
import { useEncryptedData } from "../../utils/encryptedData";
import { contacts } from "../../utils/listContacs";

import proveedor from "../../assets/proveedor.svg";

export const Home = () => {
  const ls = useEncryptedData();
  const userData = ls.get("token", { secret: process.env.REACT_APP_SECRET });

  let listData = [];

  let isStaff = false;
  let isFinanza = false;
  let isContact = false;
  let isContabilidad = false;

  if (userData !== null && userData !== undefined) {
    isStaff = userData.user.rol.includes("Staff LBO");
    isFinanza = userData.user.rol.includes("Finanzas");
    isContact = contacts.includes(userData.user.email);
    isContabilidad = userData.user.rol.includes("Conciliaciones");
  }

  if (isStaff && !(isFinanza || isContabilidad || isContact)) {
    listData = [
      {
        title: "Subir Facturas",
        path: "/Staff/subirfacturas",
        icon: (
          <img src={receipt} alt="subir factura" width="60" height="60"></img>
        )
      },
      {
        title: "Ver Facturas",
        path: `/misfacturas/${userData.user.cuil}`,
        icon: <img src={list} alt="ver factura" width="60" height="60"></img>
      },
      {
        title: "Proveedores",
        path: "/proveedores",
        icon: (
          <img
            src={proveedor}
            alt="Alta de proveedor"
            width="60"
            height="60"></img>
        )
      }
    ];
  } else if (isFinanza || isContabilidad || isContact) {
    listData = [
      {
        title: "Subir Facturas",
        path: "/Staff/subirfacturas",
        icon: (
          <img src={receipt} alt="subir factura" width="60" height="60"></img>
        )
      },
      {
        title: "Ver Facturas",
        path: "/Staff/facturas",
        icon: <img src={list} alt="ver factura" width="60" height="60"></img>
      },
      {
        title: "Proveedores",
        path: "/proveedores",
        icon: (
          <img
            src={proveedor}
            alt="Alta de proveedor"
            width="60"
            height="60"></img>
        )
      }, {
        title: "Representantes",
        path: "/representantes",
        icon: (
          <img
            src={proveedor}
            alt="Alta de proveedor"
            width="60"
            height="60"></img>
        )
      }
    ];
  } else if (userData !== null && userData !== undefined) {
    listData = [
      {
        title: "Subir mis Facturas",
        path: "/subirfacturas",
        icon: (
          <img src={receipt} alt="subir factura" width="60" height="60"></img>
        )
      },
      {
        title: "Ver mis Facturas",
        path: `/misfacturas/${userData.user.cuil}`,
        icon: <img src={list} alt="ver factura" width="60" height="60"></img>
      }
    ];
  }

  return (
    <>
      <Sidebars></Sidebars>
      <div className="b-example-divider b-example-vr">
        <h2 className="Titulo">Bienvenido a Proveedores LBO</h2>
        <div className="parent-container">
          {listData.map((value) => {
            return (
              <Link className="a" to={value.path}>
                <Card className="icon-container1" border="success">
                  <Card.Title className="material-icons">
                    <br></br>
                    <br></br>
                    {value.icon}
                  </Card.Title>
                  <Card.Body className="icon-text">
                    <Card.Title className="p">{value.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};
