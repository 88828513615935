import React from "react";
import "./submenu.css";
import { NavLink } from "react-router-dom";

const Items = ({ item }) => {
  const isExternal = item.path.startsWith("https:");

  return (
    <>
      {!isExternal ? (
        <NavLink className="sidebarlink" to={item.path}>
          {item.icon}
          <p className="SidebarLabel">{item.title}</p>
        </NavLink>
      ) : (
        <a
          className="sidebarlink"
          href={item.path}
          target="_blank"
          rel="noopener noreferrer">
          {item.icon}
          <p className="SidebarLabel">{item.title}</p>
        </a>
      )}
    </>
  );
};

export default Items;
