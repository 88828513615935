import { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useParams } from "react-router-dom";
import Sidebars from "../../sidebars/sidebars";
import { DataTableProv } from "./DataTableProv";
import { toast } from "react-toastify";
import "./myInvoices.css";

axios.defaults.withCredentials = true;

const MyInvoices = () => {
  const { cuil } = useParams();
  const [invoices, setInvoices] = useState([]);

  const getInvoices = async (cuil) => {
    const options = {
      method: "GET",
      url: `facturas/${cuil}`
    };

    await axios
      .request(options)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error: falla al traer facturas");
      });
  };

  useEffect(() => {
    getInvoices(cuil);
  }, []);

  return (
    <div>
      <Sidebars></Sidebars>
      <div className="box">
        <h2 className="title">Lista de Facturas</h2>
        <div className="table-responsive div">
          <table className="table table-sm table-striped table-hover table-bordered table2">
            <thead>
              <tr>
                <th>Fecha de carga</th>
                <th>Proveedor</th>
                <th>CBU</th>
                <th>Tipo</th>
                <th>Concepto</th>
                <th>Monto</th>
                <th>Retencion</th>
                <th>neto</th>
                <th>Comp. retenciones</th>
                <th>Contacto</th>
                <th>Factura</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {invoices ? (
                <>
                  {invoices.map((invoice, index) => {
                    return (
                      <DataTableProv
                        toast={toast}
                        invoice={invoice}
                        key={index}></DataTableProv>
                    );
                  })}
                </>
              ) : (
                <h3>No tiene Facturas Cargadas...</h3>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MyInvoices;
