import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from '../api/axios';
import { FormInvoices } from '../other/formInvoices';
import Sidebars from '../sidebars/sidebars';

axios.defaults.withCredentials = true;

const Suppliers = () => {
  const [listProv, setListProv] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [cuil, setCuil] = useState('');
  const [cbu, setCbu] = useState('');
  const [cbuUSD, setCbuUSD] = useState('');

  const handleSelectChange = (event) => {
    setName(event.value.name);
    setEmail(event.value.email);
    setCuil(event.value.cuil);
    setCbu(event.value.cbu ? event.value.cbu : 'no disponible');
    setCbuUSD(event.value.cbu_usd ? event.value.cbu_usd : 'no disponible');
  };

  const getListProv = async () => {
    const optionsProv = {
      method: 'GET',
      url: 'proveedores/'  
    };

    await axios
      .request(optionsProv)
      .then((response) => {
        setListProv(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    toast.promise(getListProv(), {
      pending: 'Cargando proveedores...',
      success: 'Carga Exitosa.',
      error: 'Error al cargar.',
    });
  }, []);

  return (
    <div>
      <Sidebars></Sidebars>
      <div>
        <FormInvoices
          user={name.replace('undefined', '')}
          email={email}
          cuil={cuil}
          cbu={cbu}
          cbuUSD={cbuUSD}
          isStaff={true}
          listProv={listProv}
          handleSelectChange={handleSelectChange}
        ></FormInvoices>
      </div>
    </div>
  );
};

export default Suppliers;
