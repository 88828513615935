import { Link } from 'react-router-dom';
import edit from '../assets/edit.svg';

export const listColumsProviders = (
  isFinanza = false,
  isContabilidad = false,
) => {
  if (isFinanza || isContabilidad) {
    return [
      {
        name: 'Nombre',
        selector: (row) => row.name,
        sortable: true,
        reorder: true,
      },

      {
        name: 'Cuil',
        selector: (row) => row.cuil,
      },

      {
        name: 'Área',
        selector: (row) => row.area,
        sortable: true,
        reorder: true,
      },
      {
        name: 'Tipo',
        selector: (row) => row.usertype,
        sortable: true,
        reorder: true,
      },
      {
        name: 'Estado',
        selector: (row) => {
          return row.state === '1' ? 'Activo' : 'Inactivo';
        },
        sortable: true,
        reorder: true,
      },
      {
        name: 'editar',
        selector: (row) => {
          return (
            <Link
              to={`/proveedores/edit/${row.cuil}`}
              state={{
                cbu: row.cbu,
                name: row.name,
                email: row.email,
                area: row.area,
                state: row.state,
                usertype: row.usertype,
                cbu_usd: row.cbu_usd,
                is_representative: row.is_representative,
              }}
            >
              <button type="button" class="btn btn-light">
                <img src={edit} alt="edit" height={20} width={20}></img>
              </button>
            </Link>
          );
        },
      },
    ];
  } else {
    return [
      {
        name: 'Nombre',
        selector: (row) => row.name,
        sortable: true,
        reorder: true,
      },

      {
        name: 'Cuil',
        selector: (row) => row.cuil,
      },

      {
        name: 'Área',
        selector: (row) => row.area,
        sortable: true,
        reorder: true,
      },
      {
        name: 'Tipo',
        selector: (row) => row.usertype,
        sortable: true,
        reorder: true,
      },
      {
        name: 'Estado',
        selector: (row) => {
          return row.state === '1' ? 'Activo' : 'Inactivo';
        },
        sortable: true,
        reorder: true,
      },
    ];
  }
};
