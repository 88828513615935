import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CurrencyInput from "../../utils/money";

import "./modal.css";
import Descargar_txt from "../../assets/Descargar_txt.svg";

const ModalInput = ({
  retenciones,
  changeRetecion,
  setRetencion,
  setfact_pdf,
  comp_retenciones,
  toast,
}) => {
  const beforeReten = retenciones === null ? null : retenciones;

  const formatterDolar = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const [activate, setActivate] = useState(false);

  const [show, setShow] = useState(false);

  const [retencionInput, setRetencionInput] = useState(
    beforeReten === null
      ? ""
      : formatterDolar.format(beforeReten).replace("€", "")
  );

  const handleShow = () => setShow(true);

  const handleCancel = () => {
    setRetencionInput(
      beforeReten === null
        ? ""
        : formatterDolar.format(beforeReten).replace("€", "")
    );

    setRetencion(beforeReten);
    setShow(false);
  };

  const handleRetenciones = (e) => {
    e.preventDefault();

    console.log(e.target.value);

    if (!e.target.value) {
      setRetencionInput("");
      setRetencion(null);
    } else {
      setRetencionInput(e.target.value);

      let value = e.target.value.replace(/\./g, "").replace(",", ".");

      setRetencion(parseFloat(value));
    }
  };

  const getPdf = async (e) => {
    e.preventDefault();

    /*const options = {
      method: "GET",
      url: comp_retenciones,
      responseType: "blob"
    };

    await axios
      .request(options)
      .then(function (response) {
        let file;
        //Create a Blob from the PDF Stream
        if (comp_retenciones.endsWith(".pdf")) {
          file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL.replace("api.", ""));
        } else {
          file = new Blob([response.data], { type: "image/*" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          fileURL.replace("api.", "");

          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `comp_retencion.png`;
          link.click();
        }
      })
      .catch(function (error) {
        console.error(error);
      });*/

    if (comp_retenciones) {
      window.open(process.env.REACT_APP_PROVEEDORES_BACK + comp_retenciones);
    } else {
      toast.info("No existe un comprobante de retencion");
    }
  };

  useEffect(() => {
    if (retenciones === null && retencionInput === "") {
      setActivate(false);
    } else {
      setActivate(true);
    }
  }, [retenciones, retencionInput]);

  return (
    <>
      <button className="button-48" onClick={handleShow}>
        <span class="text">
          {retenciones !== null
            ? formatterDolar.format(retenciones).replace("€", "")
            : "sin análisis"}
        </span>
      </button>

      <Modal show={show} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Análisis de Retención</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row">
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Monto de retención</Form.Label>
                <CurrencyInput
                  retencion={true}
                  value={retencionInput}
                  placeholder=""
                  type="text"
                  className="form-control"
                  required
                  autoFocus
                  onChange={(e) => handleRetenciones(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Cargar o cambiar comprobante de Retención
                </Form.Label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept="image/*, application/pdf"
                  onChange={(e) => {
                    if (
                      e.target.files[0].type !== "image/jpeg" &&
                      e.target.files[0].type !== "image/png" &&
                      e.target.files[0].type !== "application/pdf"
                    ) {
                      toast.error(
                        "tipo de archivo no permitido. solo PNG, JPEG Y PDF",
                        {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        }
                      );
                    } else {
                      setfact_pdf(e.target.files[0]);
                    }
                  }}
                />
              </Form.Group>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Visualización de PDF o imagen</Form.Label>
              {comp_retenciones ? (
                <div>
                  <br></br>
                  <Button variant="light" onClick={getPdf}>
                    <img src={Descargar_txt} alt="descargar txt"></img>
                  </Button>
                </div>
              ) : (
                <p className="text-warning">
                  Actualmente no contiene Pdf o imagen
                </p>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={changeRetecion}
            disabled={!activate}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalInput;
