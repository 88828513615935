import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./Form.css";

const FilterSelect = ({ filterStates = () => {} }) => {
  let listState = [
    { label: "Rechazado", value: "0" },
    { label: "Pendiente", value: "1" },
    { label: "Autorizado", value: "2" },
    { label: "Contabilizado", value: "3" },
    { label: "Por Contabilizar", value: "4" },
    { label: "Pagado", value: "5" }
  ];

  const animatedComponents = makeAnimated();

  return (
    <>
      <Select
        className="basic-multi-select"
        classNamePrefix="select"
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={[listState[1], listState[2], listState[3], listState[4]]}
        isMulti
        options={listState}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#0d9963",
            primary: "#0d9963b5"
          }
        })}
        onChange={filterStates}
      />
    </>
  );
};

export default FilterSelect;
