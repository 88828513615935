import React, { useEffect, useState } from "react";
import "./DataTable.css";

export const DataTableProv = ({ invoice, toast }) => {
  let date = new Date(invoice.date);

  const formatterDolar = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  });

  const listState = [
    "Rechazado",
    "Pendiente",
    "Autorizado",
    "Contabilizado",
    "Por Contabilizar",
    "Pagado"
  ];

  let monto = formatterDolar.format(invoice.amount).replace("€", "");
  let retencion = formatterDolar.format(invoice.retenciones).replace("€", "");
  let neto = formatterDolar
    .format(invoice.amount - invoice.retenciones)
    .replace("€", "");

  const [state, setState] = useState(listState[invoice.state]);

  useEffect(() => {
    if (
      listState[invoice.state] === "Autorizado" ||
      listState[invoice.state] === "Contabilizado" ||
      listState[invoice.state] === "Por Contabilizar"
    ) {
      setState("Pendiente");
    }
  }, []);

  const getPdf = async (e, path_pdf) => {
    e.preventDefault();
    /*
    const options = {
      method: "GET",
      url: path_pdf,
      responseType: "blob"
    };

    await axios
      .request(options)
      .then(function (response) {
        let file;
        //Create a Blob from the PDF Stream
        if (path_pdf.endsWith(".pdf")) {
          file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL.replace("api.", ""));
        } else {
          file = new Blob([response.data], { type: "image/*" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          fileURL.replace("api.", "");

          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `Factura_${invoice.id}.jpeg`;
          link.click();
        }
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.status === 404) {
          toast.info("No sé cargo el Comprobante aún");
        } else {
          toast.error("No se pudo vizualizar PDF");
        }
      });*/

    if (path_pdf) {
      window.open(process.env.REACT_APP_PROVEEDORES_BACK + path_pdf);
    } else {
      toast.info("No sé cargo el Comprobante aún");
    }
  };

  return (
    <tr>
      <td>
        {date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear()}
      </td>
      <td>{invoice.user}</td>
      <td>{invoice.cbu_fact}</td>
      <td>{invoice.type}</td>
      <td>{invoice.concept}</td>
      <td>{monto}</td>
      <td>{retencion}</td>
      <td>{neto}</td>
      <td>
        <button
          className="button-4 monto"
          onClick={(e) => getPdf(e, invoice.comp_retenciones)}>
          PDF
        </button>
      </td>
      <td>{invoice.contact}</td>
      <td>
        <button
          className="button-4 monto"
          onClick={(e) => getPdf(e, invoice.fact_pdf)}>
          PDF
        </button>
      </td>
      <td>{state}</td>
    </tr>
  );
};
