import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEncryptedData } from "../utils/encryptedData";
import { contacts } from "../utils/listContacs";

const RequireAuth = ({ rolesAuths }) => {
  const ls = useEncryptedData();
  const userData = ls.get("token", { secret: process.env.REACT_APP_SECRET });

  let isContact = false;

  let isLogin = false;
  let listrol = [];
  const location = useLocation();

  if (userData !== null && userData !== undefined) {
    isLogin = true;
    listrol = userData.user.rol;
    isContact = contacts.includes(userData.user.email);
  }

  return (userData && listrol?.find((role) => rolesAuths?.includes(role))) ||
    isContact ? (
    <Outlet />
  ) : isLogin ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
