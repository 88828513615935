import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import axios from "../api/axios";
import close from "../../assets/close.svg";
import receipt from "../../assets/receipt.svg";
import { toast } from "react-toastify";

const ModalStates = () => {
  const formatterDolar = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  const [invoices, setInvoices] = useState([]);
  const [ids, setIds] = useState([]);

  const getInvoices = async () => {
    await axios
      .request({
        method: "GET",
        headers: { "content-type": "application/json" },
        url: "facturas/?states=3&states=4",
      })
      .then((response) => {
        const filterInvoices = response.data.filter((invoice) => {
          let date = new Date(invoice.pay_date);

          return (
            invoice.retenciones !== null &&
            invoice.cbu_fact !== "no disponible" &&
            invoice.cbu_fact !== null
          );
        });

        setInvoices(filterInvoices);
        setIds(filterInvoices.map((invoice) => invoice.id));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteInvoices = (e, id, index) => {
    e.preventDefault();

    setInvoices(invoices.filter((item) => item.id !== id));
    setIds(ids.filter((item) => item !== id));
  };

  const changesStateMassive = async () => {
    const toastId = toast.loading("Cambiando estados...", {});

    await axios
      .request({
        method: "PATCH",
        headers: { "content-type": "application/json" },
        url: "facturas/states/",
        data: {
          ids: ids,
        },
      })
      .then((response) => {
        toast.dismiss(toastId);
        toast.success("Se cambiaron los estados con exito");
        handleClose();
        setInvoices([]);
        setIds([]);
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error(
          "Error al cambiar estados. chequear que facturas cambiaron el estado"
        );
        console.error(error);
      });
  };

  useEffect(() => {
    getInvoices();
  }, [show]);

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <img src={receipt} alt="cambiar estados" width={36} height={36}></img>
      </Button>

      <Modal
        dialogClassName="modal-90w"
        show={show}
        onHide={handleClose}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Va a cambiar a "pagado" estas facturas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
                <table className="table table-sm table-striped table-hover table-bordered table2staff">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Fecha de Pago</th>
                      <th>Proveedor</th>
                      <th>CBU</th>
                      <th>Tipo</th>
                      <th>Neto</th>
                      <th>Borrar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice, index) => {
                      const neto = formatterDolar
                        .format(invoice.amount - invoice.retenciones)
                        .replace("€", "");

                      const cbu = invoice.cbu_fact.slice(0, 11);
                      const cbu2 = invoice.cbu_fact.slice(11);

                      let payDate = new Date(invoice.pay_date);

                      return (
                        <tr>
                          <td>
                            <b>{invoice.id}</b>
                          </td>
                          <td>
                            {payDate.getDate() +
                              "/" +
                              (payDate.getMonth() + 1) +
                              "/" +
                              payDate.getFullYear()}
                          </td>
                          <td>{invoice.user}</td>
                          <td>{cbu + cbu2}</td>
                          <td>{invoice.type}</td>
                          <td className={"montostaff"}>{neto}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={(e) =>
                                deleteInvoices(e, invoice.id, index)
                              }
                            >
                              <img
                                src={close}
                                alt="borrar"
                                width="20"
                                height="20"
                              ></img>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={changesStateMassive}>
            Pagado
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalStates;
