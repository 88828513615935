import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import axios from "../api/axios";
import close from "../../assets/close.svg";
import Descargar_txt from "../../assets/Descargar_txt.svg";
import Select from "react-select";

const ModalTxt = () => {
  function compareFechas(date) {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const years = today.getFullYear();

    if (years >= date.getFullYear()) {
      if (month > date.getMonth() + 1) {
        return true;
      } else if (month == date.getMonth() + 1) {
        if (day >= date.getDate()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const formatterDolar = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  });

  const [show, setShow] = useState(false);

  const [invoices, setInvoices] = useState([]);
  const [listCBU, setListCBU] = useState([]);

  const getInvoices = async () => {
    await axios
      .request({
        method: "GET",
        headers: { "content-type": "application/json" },
        url: "facturas/?states=3&states=4"
      })
      .then((response) => {
        const filterInvoices = response.data.filter((invoice) => {
          let date = new Date(invoice.pay_date);

          return (
            compareFechas(date) &&
            invoice.retenciones !== null &&
            invoice.cbu_fact !== "no disponible" &&
            invoice.cbu_fact !== null &&
            invoice.empresa === "LBO S.A"
          );
        });

        setInvoices(filterInvoices);

        let Cbus = [];

        filterInvoices.forEach(() => {
          Cbus.push({
            label: "19800 MACRO",
            value: "2850793630000001980063"
          });
        });

        setListCBU(Cbus);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function modificarCBU(e, index) {
    const changeCbu = [...listCBU]; // hacer una copia de la lista original
    changeCbu[index] = e; // modificar el elemento en el índice 2

    setListCBU(changeCbu); // actualizar el estado con la nueva lista modificada
  }

  function zeroFill(number, width) {
    width -= number.toString().length;

    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
    }
    return number + ""; // siempre devuelve tipo cadena
  }

  function floatToString(amount) {
    let string = amount.toString();

    let list_string = string.split(".");

    let list_num = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

    if (string.endsWith(".0")) {
      return string.replace(".0", "00");
    } else if (!string.endsWith(".0") && !string.includes(".")) {
      return string + "00";
    } else if (list_num.includes(list_string[1])) {
      return list_string[0] + list_string[1] + "0";
    } else {
      return string.replace(".", "");
    }
  }

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  // Función para descargar el archivo de texto con los datos de la tabla
  const downloadTxt = () => {
    const date = new Date();

    const d =
      date.getFullYear() +
      zeroFill(date.getMonth() + 1, 2) +
      zeroFill(date.getDate(), 2);

    const fullYear = date.getFullYear().toString().replace("20", "");

    const d2 =
      zeroFill(date.getDate(), 7) +
      "/" +
      zeroFill(date.getMonth() + 1, 2) +
      "/" +
      fullYear;

    let amount = "";

    // Crear una cadena de texto con los datos de la tabla
    let CbuSalida = "";
    let CbuProv = "";
    let out = "";

    invoices.forEach((invoice, index) => {
      amount = zeroFill(
        floatToString(invoice.amount - invoice.retenciones),
        17
      );

      CbuSalida =
        `*U*${listCBU[index].value}D${d}N                                                             ${d2}                                                                                                                                   ` +
        "\n";
      CbuProv =
        `*M*${invoice.cbu_fact}${amount}                                                                                                      000000000000            0000000000                                                              ` +
        "\n";

      out += CbuSalida + CbuProv;
    });

    //  .map((row) => Object.values(row).join("\t"))
    // .join("\n");

    // Crear un objeto Blob con la cadena de texto
    const blob = new Blob([out], { type: "text/plain" });

    // Crear un objeto URL para el Blob
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `Txt_Interbanking.txt`;
    link.click();
  };

  const deleteInvoices = (e, id, index) => {
    e.preventDefault();

    setInvoices(invoices.filter((item) => item.id !== id));

    const changeCbu = [...listCBU];
    changeCbu.splice(index, 1);

    setListCBU(changeCbu); // actualizar el estado con la nueva lista modificada
  };

  useEffect(() => {
    getInvoices();
  }, [show]);

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <img src={Descargar_txt} alt="descargar txt"></img>
      </Button>

      <Modal
        dialogClassName="modal-90w"
        show={show}
        onHide={handleClose}
        fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Lista para descargar Txt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
                <table className="table table-sm table-striped table-hover table-bordered table2staff">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Desde</th>
                      <th>Fecha de Pago</th>
                      <th>Proveedor</th>
                      <th>CBU</th>
                      <th>Tipo</th>
                      <th>Neto</th>
                      <th>Borrar</th>
                    </tr>
                  </thead>
                  {invoices.map((invoice, index) => {
                    const neto = formatterDolar
                      .format(invoice.amount - invoice.retenciones)
                      .replace("€", "");

                    const cbu = invoice.cbu_fact.slice(0, 11);
                    const cbu2 = invoice.cbu_fact.slice(11);

                    let payDate = new Date(invoice.pay_date);

                    const CbusLbo = [{ label: "19800 MACRO", 
                    value: "2850793630000001980063" }];

                    return (
                      <tbody>
                        <tr>
                          <td>
                            <b>{invoice.id}</b>
                          </td>
                          <td>
                            <Select
                              defaultValue={CbusLbo[0]}
                              options={CbusLbo}
                              onChange={(e) => modificarCBU(e, index)}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#0d9963",
                                  primary: "#0d9963b5"
                                }
                              })}
                            />
                          </td>
                          <td>
                            {payDate.getDate() +
                              "/" +
                              (payDate.getMonth() + 1) +
                              "/" +
                              payDate.getFullYear()}
                          </td>
                          <td>{invoice.user}</td>
                          <td>{cbu + cbu2}</td>
                          <td>{invoice.type}</td>
                          <td className={"montostaff"}>{neto}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={(e) =>
                                deleteInvoices(e, invoice.id, index)
                              }>
                              <img
                                src={close}
                                alt="borrar"
                                width="20"
                                height="20"></img>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="light" onClick={downloadTxt}>
            <img src={Descargar_txt} alt="descargar txt"></img>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalTxt;
