import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEncryptedData } from "../../utils/encryptedData";
import { contacts } from "../../utils/listContacs";
import CurrencyInput from "../../utils/money";
import axios from "../api/axios";
import "./Form.css";

export const FormInvoices = ({
  user,
  email,
  cuil,
  cbu,
  cbuUSD,
  isStaff,
  listProv,
  handleSelectChange = () => {},
}) => {
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  axios.defaults.withCredentials = true;

  const ls = useEncryptedData();
  const userData = ls.get("token", { secret: process.env.REACT_APP_SECRET });

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedProvider, setIsCheckedProvider] = useState(true);

  const empresas = [
    { label: "LBO S.A", value: "LBO S.A" },
    { label: "Agrofidi", value: "Agrofidi" },
  ];

  const coins = ["$", "USD"];

  const [cbustaff, setCbuStaff] = useState("");
  const [cuilstaff, setCuilStaff] = useState("");

  const getCBU = async (optionsCbu) => {
    await axios
      .request(optionsCbu)
      .then((response) => {
        setCbuStaff(response.data.cbu);
      })
      .catch((error) => {
        console.error(error);
        setCbuStaff("no disponible");
      });
  };

  const handleOnChange = (e) => {
    setIsCheckedProvider(false);
    setIsChecked(true);
    setCuilStaff(userData.user?.cuil);
    getCBU({
      method: "GET",
      url: `cbu/${userData.user?.cuil}`,
    });
  };

  const handleOnChangeProvider = (e) => {
    setIsCheckedProvider(true);
    setIsChecked(false);
    setCbuStaff("");
    setCuilStaff("");
  };

  const d = new Date();

  const options = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "Otro", value: "Otro" },
  ];

  // data invoices
  const [concept, setconcept] = useState("");
  const [inputamount, setinputamount] = useState("");
  const [fact_pdf, setfact_pdf] = useState([]);
  const [contact, setContact] = useState("");
  const [type, settype] = useState(options[0].value);
  const [observation, setobservation] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [button, setButton] = useState(true);
  const [currency, setCurrency] = useState(coins[0]);

  function handleSelect(e) {
    setCurrency(e.target.value);
  }

  const postInvoices = async (e) => {
    e.preventDefault();

    const toastId = toast.loading("Subiendo Factura...");

    if (fact_pdf.length === 0) {
      toast.dismiss(toastId);
      toast.warning("tiene que subir un PDF o IMG", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      setButton(true);

      let value = inputamount.replace(/\./g, "").replace(",", ".");

      let amount_float = parseFloat(value);

      const option = {
        method: "POST",
        url: "facturas/create/",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: {
          user: user,
          type: type,
          concept: concept,
          state: 1,
          amount: amount_float,
          fact_pdf: fact_pdf,
          cbu_fact: isChecked ? cbustaff : currency === coins[0] ? cbu : cbuUSD,
          contact: contact,
          cuil: isChecked ? cuilstaff : cuil,
          retenciones:
            type === options[1].value || type === options[2].value ? 0 : null,
          reintegro: isChecked,
          empresa: empresa,
          observation: observation,
          uploader_user: userData.user.email,
          currency: currency,
          is_representative: false,
          exeption: false,
        },
      };

      await axios
        .request(option)
        .then(function (response) {
          if (response.status === 201) {
            toast.dismiss(toastId);

            setconcept("");
            setfact_pdf([]);
            document.getElementById("formFile").value = "";
            setinputamount("");
            setobservation("");

            toast.success("La Factura se subió exitosamente.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch(function (error) {
          console.error(error);

          if (error?.response?.status > 400 && error?.response?.status < 500) {
            toast.dismiss(toastId);

            if (Array.isArray(error.response.data.error)) {
              error.response.data.error.forEach((err) => {
                toast.error(err, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              });
            } else {
              toast.error("Error: " + error.response.data.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          } else if (error?.response?.status === 413) {
            toast.dismiss(toastId);
            toast.error("Error: el archivo excede el tamaño máximo permitido", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.dismiss(toastId);
            toast.error("Error: " + error.response.data.error, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setButton(false);
          }
        });
    }
  };

  const viewButton = () => {
    const validCBU =
      currency === coins[0]
        ? (cbu !== "No existe CBU" && cbu !== "no disponible") || isChecked
        : (cbuUSD !== "No existe CBU" && cbuUSD !== "no disponible") ||
          isChecked;

    const contactValid = contact;

    if (
      inputamount !== "" &&
      fact_pdf.length !== 0 &&
      concept.trim() &&
      user.trim() &&
      empresa.trim() &&
      contactValid &&
      validCBU
    ) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const money = (e) => {
    setinputamount(e.target.value);
  };

  useEffect(() => {
    viewButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputamount,
    concept,
    fact_pdf,
    user,
    empresa,
    contact,
    cbu,
    isChecked,
    cbuUSD,
    currency,
  ]);

  return (
    <div>
      <div className="divform column">
        <br></br>
        <h2 className="titleform">Subir Facturas</h2>

        <br></br>
        {isStaff ? (
          <div>
            <div className="mb-3 rowform selectMobile">
              <label for="staticEmail" className="col-sm-2 col-form-label">
                Tipo de pago:
              </label>

              <FormGroup
                style={{
                  display: "block",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      size="small"
                      checked={isCheckedProvider}
                      onChange={handleOnChangeProvider}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Al proveedor"
                  size="small"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="success"
                      size="small"
                      checked={isChecked}
                      onChange={handleOnChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Es reintegro"
                  size="small"
                />
              </FormGroup>
            </div>
            <div className="mb-3 rowform selectMobile">
              <label for="staticEmail" className="col-sm-2 col-form-label">
                Proveedor:
              </label>
              <div className="col-sm-10">
                <Select
                  className="form-control-plaintext"
                  options={listProv.map((sup) => ({
                    label: sup.name,
                    value: sup,
                  }))}
                  onChange={handleSelectChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#0d9963",
                      primary: "#0d9963b5",
                    },
                  })}
                />
              </div>
            </div>
            {isChecked ? (
              <Alert key={"info"} variant={"info"}>
                El pago del <b>reintegro</b> se realizará a tu CBU.
              </Alert>
            ) : user ? (
              currency === coins[0] ? (
                cbu !== "no disponible" ? (
                  <Alert key={"info"} variant={"info"}>
                    El pago se realizará al CBU: {cbu}.
                  </Alert>
                ) : (
                  <Alert show={true} variant="warning">
                    <Alert.Heading>
                      El CBU de {user} no está vinculado
                    </Alert.Heading>
                    <p>
                      No se podrá subir la factura. Tienes que enviar un email a
                      contabilidad con el CUIL y CBU del proveedor para realizar
                      la vinculación.{" "}
                      <a
                        href="https://mail.google.com/mail/?view=cm&to=compras@lbo.com.ar"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        compras@lbo.com.ar
                      </a>
                    </p>
                  </Alert>
                )
              ) : cbuUSD !== "no disponible" ? (
                <Alert key={"info"} variant={"info"}>
                  El pago se realizará al CBU en USD: {cbuUSD}.
                </Alert>
              ) : (
                <Alert show={true} variant="warning">
                  <Alert.Heading>
                    El CBU en USD de {user} no está vinculado
                  </Alert.Heading>
                  <p>
                    No se podrá subir la factura. Tienes que enviar un email a
                    contabilidad con el CUIL y CBU del proveedor para realizar
                    la vinculación.{" "}
                    <a
                      href="https://mail.google.com/mail/?view=cm&to=compras@lbo.com.ar"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      compras@lbo.com.ar
                    </a>
                  </p>
                </Alert>
              )
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Empresa:
          </label>
          <div className="col-sm-10">
            <Select
              options={empresas}
              onChange={(e) => {
                setEmpresa(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
              className="form-control-plaintext"
            />
          </div>
        </div>

        <div className="mb-3 rowform selectMobile">
          <label for="staticEmail" className="col-sm-2 col-form-label">
            Tipo:
          </label>
          <div className="col-sm-10">
            <Select
              defaultValue={options[0]}
              options={options}
              onChange={(e) => {
                settype(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
              className="form-control-plaintext"
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Fecha:</label>
          <div className="col-sm-5">
            <p className="form-control-plaintext">
              {d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()}
            </p>
          </div>
          <label for="staticEmail" class="col-sm-2 col-form-label">
            Email:
          </label>
          <div className="col-sm-5">
            <p className="form-control-plaintext">{email}</p>
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Cuil:</label>
          <div className="col-sm-5">
            <p
              className={
                isChecked
                  ? "form-control-plaintext text-success"
                  : "form-control-plaintext"
              }
            >
              {isChecked ? cuilstaff : cuil}
            </p>
          </div>
          <label className="col-sm-2 col-form-label">CBU:</label>
          <div className="col-sm-5">
            <p
              className={
                isChecked
                  ? "form-control-plaintext text-success"
                  : currency === coins[0]
                  ? cbu !== "no disponible"
                    ? "form-control-plaintext"
                    : "form-control-plaintext text-danger col-sm-5"
                  : cbuUSD !== "no disponible"
                  ? "form-control-plaintext"
                  : "form-control-plaintext text-danger col-sm-5"
              }
            >
              {isChecked ? (
                cbustaff
              ) : currency === coins[0] ? (
                cbu !== "no disponible" ? (
                  cbu
                ) : (
                  <div
                    style={{
                      width: "65%",
                      fontSize: "85%",
                      fontWeight: "700",
                    }}
                  >
                    Desde contabilidad se tiene que vincular el CBU para
                    realizar el pago
                  </div>
                )
              ) : cbuUSD !== "no disponible" ? (
                cbuUSD
              ) : (
                <div
                  style={{
                    width: "65%",
                    fontSize: "85%",
                    fontWeight: "700",
                  }}
                >
                  Desde contabilidad se tiene que vincular el CBU para realizar
                  el pago
                </div>
              )}
            </p>
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Monto:</label>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <CurrencyInput
              placeholder=""
              type="text"
              className="form-control"
              value={inputamount}
              onChange={(e) => {
                money(e);
              }}
              required
            />
            <Form.Select
              style={{
                width: "20%",
                "margin-left": "1rem",
              }}
              onChange={handleSelect}
            >
              <option defaultValue={coins[0]}>{coins[0]}</option>
              <option value={coins[1]}>{coins[1]}</option>
            </Form.Select>
          </div>
        </div>
        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Concepto:</label>
          <div className="col-sm-10">
            <input
              value={concept}
              type="text"
              className="form-control"
              maxLength={100}
              onChange={(e) => setconcept(e.target.value)}
              required
            />
          </div>
        </div>
        {isStaff ? (
          <div className="mb-3 rowform">
            <label className="col-sm-2 col-form-label">Observación:</label>
            <div className="col-sm-10">
              <input
                value={observation}
                maxLength={100}
                type="text"
                className="form-control"
                placeholder="(opcional)"
                onChange={(e) => setobservation(e.target.value)}
                required
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="mb-3 rowform selectMobile">
          <label className="col-sm-2 col-form-label">Contacto LBO:</label>
          <div className="col-sm-10">
            <Select
              options={contacts.map((sup) => ({
                value: sup,
                label: sup,
              }))}
              onChange={(e) => {
                setContact(e.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#0d9963",
                  primary: "#0d9963b5",
                },
              })}
              className="form-control-plaintext"
            />
          </div>
        </div>

        <div className="mb-3 rowform">
          <label className="col-sm-2 col-form-label">Archivo:</label>
          <div className="col-sm-10">
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={(e) => {
                if (
                  !e.target.files[0].type.includes("image") &&
                  e.target.files[0].type !== "application/pdf"
                ) {
                  toast.error(
                    "tipo de archivo no permitido. solo PNG, JPG, JPEG Y PDF",
                    {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    }
                  );
                } else {
                  setfact_pdf(e.target.files[0]);
                }
              }}
            />
          </div>
        </div>
        <div className="mb-3 rowform">
          <button
            disabled={button}
            className={button ? "button-9 act" : "button-9"}
            onClick={postInvoices}
          >
            Subir Factura
          </button>
        </div>
      </div>
    </div>
  );
};
