import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useRef, useEffect } from "react";
import axios from "../api/axios";

const Search = ({ urlBase, setFilter, labelInput }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      await axios
        .request({
          method: "GET",
          headers: { "content-type": "application/json" },
          url: urlBase
        })
        .then((response) => {
          setFilter(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const filterSeaarch = async (e) => {
    e.preventDefault();

    if (searchText !== "") {
      const urlSearch = urlBase.includes("proveedores")
        ? `${urlBase}?search=${searchText}`
        : `${urlBase}&search=${searchText}`;
      await axios
        .request({
          method: "GET",
          headers: { "content-type": "application/json" },
          url: urlSearch
        })
        .then((response) => {
          setFilter(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      filterSeaarch(e);
    }
  };

  return (
    <div>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 250
        }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={labelInput}
          inputProps={{ "aria-label": "search google maps" }}
          onChange={handleSearch}
          onKeyPress={handleKeyPress}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={(e) => filterSeaarch(e)}>
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      </Paper>
    </div>
  );
};
export default Search;
